export async function checkResponse<TBody>(response: Response): Promise<TBody> {
  if (response.status >= 400 && response.status < 600) {
    let errorDetails;
    try {
      errorDetails = await response.json();
    } catch (e) {
      errorDetails = 'no details';
    }
    throw new ApiError(response.statusText, response.status, errorDetails);
  } else {
    if (response.statusText === 'No Content' || response.status === 204) {
      return Promise.resolve({} as TBody);
    }
    if (response.headers.get('content-type')?.includes('text/html')) {
      return { message: await response.text() } as unknown as TBody;
    }
    if (response.headers.get('content-type')?.includes('text/csv')) {
      return { csv: await response.blob() } as unknown as TBody;
    }

    return response.json();
  }
}

export class ApiError<TErrorDetails extends any = any> extends Error {
  constructor(public message: string, public status: number, public details: TErrorDetails) {
    super(message);
  }
}

export function isApiError(err: any): err is ApiError {
  return !!(err as ApiError)?.status;
}

class AbortError extends Error {
  constructor(message: string = 'Aborted') {
    super(message);
  }
}

export function isAbortError(err: any): err is AbortError {
  return (err as AbortError).name === 'AbortError';
}

export function sleep(r: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, r));
}
