import { createTRPCProxyClient, httpLink } from '@trpc/client';
import type { AppRouter } from '@sportscardinvestor/market-movers-api-client';
import {
  useQuery,
  useQueries,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useQueryClient,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  QueryFunctionContext,
} from 'react-query';
import publicConfig from '../../publicConfig';
import { ApiError } from '../../utils/api';
import useAuth from '../../features/auth/useAuth';
import { getAuthTokenFromLocalStorage, checkIfAuthError, withAuth } from './authentication';
import { TRPCError, authQueryKey } from './types';

export type {
  RouterInput as MmApiInput,
  RouterOutput as MmApiOutput,
} from '@sportscardinvestor/market-movers-api-client';
export type { TRPCError } from './types';

export const mmApiClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      url: `${publicConfig.NEXT_PUBLIC_MARKET_MOVERS_API_BASE_URL}/trpc`,
      headers: () => {
        const authToken = getAuthTokenFromLocalStorage();
        return {
          Authorization: authToken ? `Bearer ${authToken}` : undefined,
        };
      },
    }),
  ],
});

export type MmApiQueryOptions<TData, TKey extends readonly unknown[]> = UseQueryOptions<
  TData,
  TRPCError | ApiError,
  TData,
  TKey
>;

export function useAuthenticatedMMAPIQuery<TData, TKey extends readonly unknown[]>(
  key: TKey,
  fn: () => Promise<TData>,
  options?: MmApiQueryOptions<TData, TKey>
) {
  const { isLoggedIn } = useAuth();
  const queryClient = useQueryClient();
  const result = useQuery(key, withAuth(fn, queryClient), {
    ...(options ?? {}),
    enabled: options?.enabled !== false && !!isLoggedIn,
    onError: (...params) => {
      const [error] = params;
      if (checkIfAuthError(error)) {
        queryClient.refetchQueries(authQueryKey);
      }
      options?.onError?.(...params);
    },
  });

  return result;
}

export function useAuthenticatedMMAPIQueries<TData, TKey extends readonly unknown[]>(
  queries: {
    queryKey: TKey;
    queryFn: () => Promise<TData>;
    options?: MmApiQueryOptions<TData, TKey>;
  }[]
) {
  const { isLoggedIn } = useAuth();
  const queryClient = useQueryClient();
  return useQueries(
    queries.map(({ queryFn, queryKey, options }) => ({
      ...(options ?? {}),
      queryFn,
      queryKey,
      enabled: options?.enabled !== false && !!isLoggedIn,
      onError: (error: TRPCError | ApiError<any>) => {
        if (checkIfAuthError(error)) {
          queryClient.refetchQueries(authQueryKey);
        }
        options?.onError?.(error);
      },
    }))
  );
}

export type MmApiInfiniteQueryOptions<TData, TKey extends readonly unknown[]> = UseInfiniteQueryOptions<
  TData,
  TRPCError,
  TData,
  TData,
  TKey
>;

export function useAuthenticatedMMAPIInfiniteQuery<TData, TKey extends readonly unknown[], TPageParam = any>(
  key: TKey,
  fn: (context: QueryFunctionContext<TKey, TPageParam>) => Promise<TData>,
  options?: MmApiInfiniteQueryOptions<TData, TKey>
) {
  const { isLoggedIn } = useAuth();
  const queryClient = useQueryClient();
  return useInfiniteQuery(key, withAuth(fn, queryClient), {
    ...(options ?? {}),
    enabled: options?.enabled !== false && !!isLoggedIn,
    onError: (...params) => {
      const [error] = params;
      if (checkIfAuthError(error)) {
        queryClient.refetchQueries(authQueryKey);
      }
      options?.onError?.(...params);
    },
  });
}

export function useAuthenticatedMMApiMutation<TData, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TRPCError | ApiError, TVariables>
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...(options ?? {}),
    mutationFn: (...vars) => {
      const authToken = getAuthTokenFromLocalStorage();
      if (!authToken) {
        throw new ApiError('Unauthenticated', 401, 'No access token');
      }
      return mutationFn(...vars);
    },
    onError: (...params) => {
      const [error] = params;
      if (checkIfAuthError(error)) {
        queryClient.refetchQueries(authQueryKey);
      }
      options?.onError?.(...params);
    },
  });
}

export function usePublicMMApiMutation<TData, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TRPCError | ApiError, TVariables>
) {
  return useMutation({
    ...(options ?? {}),
    mutationFn,
  });
}
