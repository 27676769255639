import type { Analytics } from 'firebase/analytics';
import firebaseAppPromise from '../index';
import { MiscEvent } from './events/miscellaneous';
import { DashboardEvent } from './events/dashboard';
import { CollectionEvent } from './events/collection';
import { ChartsEvent } from './events/charts';
import { FavoritesEvent } from './events/favorites';
import { AlertsEvent } from './events/alerts';
import { CollectibleEvent } from './events/collectibles';
import { SearchEvent } from './events/search';
import { SalesEvent } from './events/sales';
import { SupportEvent } from './events/support';
import { StatsEvent } from './events/stats';
import { VideosEvent } from './events/videos';
import { AccountEvent } from './events/account';
import { DealsEvent } from './events/deals';
import { RatiosEvent } from './events/ratios';
import { User } from 'features/auth/types';

// NOTE: dynamic import to exclude from bundle and only fetch on clientside
const analyticsModule = import('firebase/analytics');
const analyticsPromise: Promise<Analytics | null> = (async () => {
  const [firebaseApp, { getAnalytics, isSupported }] = await Promise.all([firebaseAppPromise, analyticsModule]);
  return firebaseApp && (await isSupported()) ? getAnalytics(firebaseApp) : null;
})();

export type AnalyticsEvent =
  | AccountEvent
  | MiscEvent
  | DashboardEvent
  | CollectionEvent
  | ChartsEvent
  | FavoritesEvent
  | AlertsEvent
  | CollectibleEvent
  | SearchEvent
  | SalesEvent
  | SupportEvent
  | StatsEvent
  | VideosEvent
  | DealsEvent
  | RatiosEvent;

export async function trackEvent({ eventName, ...otherParams }: AnalyticsEvent) {
  const [{ logEvent }, analytics] = await Promise.all([analyticsModule, analyticsPromise]);
  if (analytics) {
    logEvent(analytics, eventName, otherParams);
  } else {
    console.debug('Would track analytics event if it was initialized: ', {
      eventName,
      ...otherParams,
    });
  }
}

export async function identify(user: User | null) {
  const [{ setUserId, setUserProperties }, analytics] = await Promise.all([analyticsModule, analyticsPromise]);
  if (analytics && user) {
    setUserId(analytics, String(user.id));
    setUserProperties(analytics, {
      membershipTier: user.membershipTier,
      isAdmin: user.isAdmin,
    });
  }
}
