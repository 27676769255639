import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { faMessageQuestion, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IconButton from '../../sci-ui-components/buttons/IconButton/IconButton';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import classes from './HelpCallToAction.module.scss';
import { TUTORIALS_BY_PAGE_URL } from './constants';

const HelpCallToAction = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const inactiveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [hasDismissedMessage, setHasDismissedMessage] = useState(false);
  const { learnMoreCtaMaxDisplayCount = 0, learnMoreCtaDisplayDelayInSeconds, showLearnMoreCta = false } = useFlags();
  const [numberOfLearnMoreCtaDismissals, setNumberOfLearnMoreCtaDismissals] = useLocalStorageValue<number>(
    'learn-more-cta-dismissal-number',
    true
  );

  const { pathname } = useRouter();

  const extraInfo = useMemo(() => {
    setIsMessageVisible(false);
    setHasDismissedMessage(false);
    return TUTORIALS_BY_PAGE_URL.find((item) => pathname.includes(item.pageUrl));
  }, [pathname]);

  const timeoutNotifier = () => {
    setIsMessageVisible(true);
  };

  const handleCancel = () => {
    setIsMessageVisible(false);
    setHasDismissedMessage(true);
    setNumberOfLearnMoreCtaDismissals((numberOfLearnMoreCtaDismissals || 0) + 1);
  };

  useEffect(() => {
    const handleMouseMove = () => {
      if (inactiveTimeout.current) {
        clearTimeout(inactiveTimeout.current);
      }
      if (
        !hasDismissedMessage &&
        learnMoreCtaDisplayDelayInSeconds &&
        (numberOfLearnMoreCtaDismissals || 0) < learnMoreCtaMaxDisplayCount
      ) {
        inactiveTimeout.current = setTimeout(() => timeoutNotifier(), learnMoreCtaDisplayDelayInSeconds * 1000);
      }
    };

    if (learnMoreCtaMaxDisplayCount && !hasDismissedMessage) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('wheel', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.addEventListener('wheel', handleMouseMove);
    };
  }, [
    hasDismissedMessage,
    learnMoreCtaDisplayDelayInSeconds,
    numberOfLearnMoreCtaDismissals,
    learnMoreCtaMaxDisplayCount,
  ]);

  const shouldShowCTA = useMemo(
    () => isMessageVisible && extraInfo && !isMobile && showLearnMoreCta && !hasDismissedMessage,
    [isMessageVisible, extraInfo, showLearnMoreCta, hasDismissedMessage]
  );

  return (
    <>
      {shouldShowCTA ? (
        <div className={classes.helpContainer}>
          <IconButton
            faIcon={faXmarkCircle}
            className={classes.closeButton}
            size={'small'}
            color={'grey'}
            title={''}
            onClick={handleCancel}
          />
          <div className={classes.popupContainer}>
            <FontAwesomeIcon icon={faMessageQuestion} className={classes.bigIcon} />
            <div className={classes.textContainer}>
              <div className={classes.title}>Need Help?</div>
              <div className={classes.subTitle}>Do you need help using the {extraInfo?.featureName} feature?</div>
              <a className={classes.link} href={extraInfo?.videoUrl} target={'_blank'} rel="noreferrer">
                Click here to watch a video.
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {children}
    </>
  );
};

export default HelpCallToAction;
